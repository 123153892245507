// IIFE - Immediately Invoked Function Expression
(function($, window, document) {

    // The $ is now locally scoped 

    // Listen for the jQuery ready event on the document
    $(function() {

        // The DOM is ready!

    });

    timeForLaunch = -1;

    function setTimeForLaunch(){
        var painel = $("div[data-launch]");

        var dateTime = painel.attr("data-launch");//"10/04/2019 11:45:31";
        var date = dateTime.split(' ')[0];
        var time = dateTime.split(' ')[1];
        date = date.split('/').reverse().join('/');
        dateTime = date+" "+time;
        timeForLaunch = (Date.parse(dateTime) - Date.parse(new Date()))/1000;
        
        setTimeout(setTimeForLaunch,60000);

    }

    if($("div[data-launch]").length > 0){
        setTimeForLaunch();
    }

    setInterval(function(){

        if(timeForLaunch > 0){
            var painel = $("div[data-launch]");
            timeForLaunch--;
            var days = parseInt( timeForLaunch / 86400);
            var rest = timeForLaunch % 86400;
            var hours = parseInt( rest / 3600);
            rest = timeForLaunch % 3600;
            var minutes = parseInt(rest / 60);
            var seconds = parseInt(rest % 60);

            painel.find('.lp-clock-day>span.value')[0].innerText = days;
            painel.find('.lp-clock-hour>span.value')[0].innerText = hours<10?0+""+hours:hours;
            painel.find('.lp-clock-minutes>span.value')[0].innerText = minutes<10?0+""+minutes:minutes;
            painel.find('.lp-clock-seconds>span.value')[0].innerText = seconds<10?0+""+seconds:seconds;
        }

    },1000);    



  	var header = document.querySelectorAll(".dynamic-header");
    if (header.length) { 
        /*if (!header.length) { */
        /*return; */
        var headerHeight = header[0].offsetHeight;
        window.onscroll = function() {
          /*console.log(headerHeight)*/
        	var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
        	if (scrollPosition > headerHeight && window.innerWidth > 992) {
        			header[0].classList.add('np-header-tiny');
        	}else{
        			header[0].classList.remove('np-header-tiny');
        	}
        };
    }


    /*Scroll anchor Nav*/
    var anchorNav = document.getElementById("anchorNav");
    if (anchorNav) {
        var header = document.querySelectorAll(".bau-header");
        var headerHeight = header[0].offsetHeight;
        var navTopOffset = anchorNav.offsetTop;
        //var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
        /*var navTopOffset = anchorNav.offsetHeight;*/
        var navFromTop = (navTopOffset - headerHeight);
        var navFromTopPlus = navFromTop + (anchorNav.offsetHeight / 4);
        
        window.onscroll = function() {
          /*console.log(scrollPosition  navTopOffset);*/
            var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

            if (scrollPosition > headerHeight && window.innerWidth > 992) {
                    header[0].classList.add('bau-header-fixed');
            }else{
                    header[0].classList.remove('bau-header-fixed');
            }
            /*if (scrollPosition > navFromTopPlus && window.innerWidth > 992) {*/
            if (scrollPosition > navFromTopPlus) {
                    anchorNav.classList.add('-pages-anchor-fixed');
                    anchorNav.style.top = headerHeight+'px';
            }else{
                    anchorNav.classList.remove('-pages-anchor-fixed');
                    anchorNav.style = '';
            }
        };
    }

    /*Anchor scroll*/
    $('.-pages-anchor-link').click(function(e) {
        e.preventDefault();
        var target = $(this).attr("href");
        if (target.indexOf("#") >= 0) {
            var scrTop = $(target).offset().top;
            var root = $('html, body');
            $('.-pages-anchor-link').removeClass('-active');
            $(this).addClass('-active');  
            root.animate({scrollTop: scrTop}, 100);
        }
    });
    

    // Window Resize implement data attr on offcanvas menu
    var windowWidthCheckScroll = function(event) {
      var header = document.querySelectorAll(".dynamic-header");
       /* $(window).on(event, function() {
            if ($(window).width() < 769) {
                console.log('mobile');
            } else {
                console.log('desk')
                console.log(headerHeight )
                var body = $('body');
                $(window).scroll(function() {
                    if ($(this).scrollTop() > headerHeight) {
                        body.addClass('bri-header-fixed');
                    } else {

                        body.removeClass('bri-header-fixed');
                    }
                });
            }
        });*/
    }

    windowWidthCheckScroll('resize');
    windowWidthCheckScroll('load');

    /*Dropdown mobile*/
    $('.dropdown-toggle-hover').click(function(){
        if($(this).closest('.dropdown').hasClass('dropdown-submenu') && $(window).width() > 769){

        }else{
            event.preventDefault();
        }
      if ($(window).width() < 769) {
        var ddl = $(this).closest('.dropdown');
        ddl.toggleClass('open');
      }
    });

    /*Mobile return dropdown trigger*/
    $('.sub-menu').before().click(function(e){
        if( $(window).width() < 769 ){

            $(this).closest('.open').removeClass('open');
        }
        // console.log('before clicked');
    });


    /*Accordion trigger*/
    $('.-accordion').click(function(){
        $(this).toggleClass('open');
    });

    /*Custom Select*/
    $('select.-custom-select').each(function(){
        var $this = $(this), numberOfOptions = $(this).children('option').length;
      
        $this.addClass('-select-hidden'); 
        $this.wrap('<div class="-select"></div>');
        $this.after('<div class="-select-styled"></div>');

        var $styledSelect = $this.next('div.-select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': '-select-options'
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
            //if ($this.children('option').eq(i).is(':selected')){
            //  $('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected')
            //}
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.click(function(e) {
            e.stopPropagation();
            $('div.-select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.-select-options').hide();
                $(this).closest('.-form-group-select').removeClass('-active');
            });
            $(this).toggleClass('active').next('ul.-select-options').toggle();
            $(this).closest('.-form-group-select').toggleClass('-active');
        });
      
        $listItems.click(function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $styledSelect.closest('.-form-group-select').removeClass('-active');
            $this.val($(this).attr('rel'));
            $list.hide();
            //console.log($this.val());
        });
      
        $(document).click(function() {
            $styledSelect.removeClass('active');
            $styledSelect.closest('.-form-group-select').removeClass('-active');
            $list.hide();
        });

    });

    //custom select actions
    $('.-select-options li').click(function() {
        var selectedOption = $(this).attr('rel');
        var selectHidden = $(this).closest('.-select').find('.-select-hidden');
        
        var filterAction = selectHidden.data('filter-action');
        
        var items = $('.-filter-grid .-card');
        var text = (selectedOption !== '*' ? selectedOption : '');

        if (filterAction == 'tags') {

            if (selectedOption == '*') {
                /*console.log('load all segments');*/
                items.removeClass('-hidden');
                $('.-filter-clear').removeClass('-active');
            }else{
                $('.-filter-clear').addClass('-active');
            }

            var pat = new RegExp(text, 'i');
            for (var i=0; i < items.length; i++) {
                var item = items[i];
                
                if (pat.test(item.innerHTML)) {
                    //console.log(item.innerHTML);
                    item.classList.add("-show");
                    item.classList.remove("-hidden");
                    //item.fadeIn();
                }else {
                    item.classList.remove("-show");
                    item.classList.add("-hidden");
                    //item.fadeIn();
                }

            }
            updateCountRows();
        }else if(filterAction == 'categories'){

            if (selectedOption == '*') {
                console.log('load all solution');
                $('.-filter-clear').removeClass('-active');
            }else{
                $('.-filter-clear').addClass('-active');

            }
            var category = (selectedOption !== '*' ? selectedOption : null);
            filterbyCategory(category);
        }
    });

 
    /*Update each row count(n) after select trigger */
    function updateCountRows(){
        var searchField = $('#search-filter');
        $('.-solutions-row').each(function(i){
            var count;
            if( searchField.val() != ''){
                console.log('serch field not empty');
                count = $(this).find('.-card.-show.-search-show').length;
                //showSearchResults(inputQuicksearch.val());
            }else{
                console.log('serch field empty');
                count = $(this).find('.-card:not(.-hidden)').length;
            }
            var countLabel = '('+count+')';
            
            $(this).find('.filter-counter').text(countLabel);
            $(this).attr('data-count-items', count);

        });
    }

    /*Update each row count(n) after typing search input */
    function updateCountAfterSearch(){
        var searchField = $('#search-filter').val();

        if( searchField !== ''){
            
            $('.-filter-row').each(function(i){
                var countSearch = $(this).find('.-card.-search-show:not(.-hidden)').length;
                var label = '('+countSearch+')';
                $(this).find('.filter-counter').text(label);
                $(this).attr('data-count-items', countSearch);
            });           
        }
        
    }    

    /*Filter products by category after select trigger*/
    function filterbyCategory(category){
        $('.-solutions-row').each(function(i){
            var count = $(this).attr('data-count-items');
            var categoryRow = $(this).attr('data-category');
            if(category){
                if (categoryRow == category) {
                    $(this).removeClass('-hidden');
                }else{
                    $(this).addClass('-hidden');
                }
            }else{
                $(this).removeClass('-hidden');
            }
        });
    }

    /*Trigger to Show search results text*/
    function showSearchResults(searchFor){
        var visibleItems = 0;
        var testResult = '';
        var card = $('.-filter-grid .-card');
        /*var visibleCount = $('.-filter-row:not(.-hidden) .-card.-search-show').length;*/
        var visibleCount = $('.-filter-row:not(.-hidden) .-card:not(.-hidden):not(.-search-hidden)').length;
        //console.log(visibleCount);

        if (visibleCount > 0 && searchFor != '') {
            $('.-filter-grid').removeClass('nothing-found');
            $('.-filter-nothing-found').removeClass('visible');

            if (visibleCount == 1) {
                testResult = 'Foi encontrado "'+visibleCount+'" resultado para a busca "'+searchFor+'"';
            }else{
                testResult = 'Foram encontrados "'+visibleCount+'" resultados para a busca "'+searchFor+'"';
            }
            //$('#-filter-results-text').text('Foram encontrados "'+visibleItems+'" resultados para a busca "Franquia"');
            $('#filter-results-text').text(testResult);
            
        }else{
            
            $('.-filter-grid').addClass('nothing-found');
            $('.-filter-nothing-found').addClass('visible');
            //console.log(visibleCount);
            /*testResult =*/
            $('#filter-results-text').empty();

        }
        
    }

    /*Quick search Filter*/
    var inputQuicksearch = $('#search-filter');
    
    if (inputQuicksearch.length) {
        var cards = $('.-card');

        inputQuicksearch.on('keyup', function(ev){
            if ($(this).val() != '') {
                var text = ev.target.value;
                var pat = new RegExp(text, 'i');

                for (var i=0; i < cards.length; i++) {
                    var item = cards[i];
                    var closeRowClasses = item.closest('.-filter-row').classList;
                    var itemClasses = item.classList;

                    if (pat.test(item.innerText)) {
                        item.classList.add("-search-show");
                        item.classList.remove("-search-hidden");
                    }else{
                        item.classList.remove("-search-show");
                        item.classList.add("-search-hidden");
                    
                    }
                }

                updateCountAfterSearch();
                showSearchResults($(this).val());  
                $('.-filter-clear').addClass('-active');              

                
            }else{
                console.log('now search field is empty');
                cards.removeClass('-search-hidden').removeClass('-search-show');
                updateCountRows();
                $('#filter-results-text').empty();
                $('.-filter-grid').removeClass('nothing-found');
                $('.-filter-nothing-found').removeClass('visible');
                
            }
        });
    }


    /*toggle clear filters button*/
    $('#clear-filters').click(function(){
        /*alert('toggle')*/
        $(this).removeClass('-active');
        $('.-select-options li[rel="*"]').click();

        if ( $('#search-filter') != '' ) {
            $('.-filter-grid').removeClass('nothing-found');
            $('.-filter-nothing-found').removeClass('visible');
            $('.-card').removeClass('-search-hidden');
            $('#filter-results-text').text('');
            $('#search-filter').val('');
        }
        
    });

    
    

    /*Main Slick*/
    var mainSlick =  $('#main-slider'); 
    var mainSlickstatus = $('.mainInfo');

    if (mainSlick.length) {
        mainSlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            var lang = mainSlickstatus.data('lang'), prefix, of;
            
            if (lang == 'pt') {
                prefix = 'Exibindo ';
                of = ' de ';
            }else if(lang == 'en'){
                prefix = 'Showing ';
                of = ' of ';
            }else if(lang == 'es'){
                prefix = 'Mostrando ';
                of = ' de ';
            }
            var i = (currentSlide ? currentSlide : 0) + 1;
            mainSlickstatus.text(prefix + i + of + slick.slideCount);
        });

    
        mainSlick.slick({
          dots: false,
          arrows: false,
          infinite: false,
          speed: 300,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              }
            },
            /*{
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }*/
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]         
        });

        // Custom carousel nav
        $('.main-slider-prev').click(function(){ 
            $(this).closest('.-home-highlight-wrap').find('#main-slider').slick('slickPrev');
        } );
        
        $('.main-slider-next').click(function(e){
            e.preventDefault(); 
            $(this).closest('.-home-highlight-wrap').find('#main-slider').slick('slickNext');
        } );
    }

    /*Brands Slick*/
    var brandsSlick =  $('.brands-slider'); 

    if (brandsSlick.length) {
    
        brandsSlick.slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]         
        });
    }    


    /*out of continer Slick*/
    var oocSlick =  $('.-ooc-slider'); 
    var oocSlickstatus = $('.oocInfo');

    if (oocSlick.length) {

        oocSlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
            var lang = oocSlickstatus.data('lang'), prefix, of;
            
            if (lang == 'pt') {
                prefix = 'Exibindo ';
                of = ' de ';
            }else if(lang == 'en'){
                prefix = 'Showing ';
                of = ' of ';
            }else if(lang == 'es'){
                prefix = 'Mostrando ';
                of = ' de ';
            }
            var i = (currentSlide ? currentSlide : 0) + 1;
            oocSlickstatus.text(prefix + i + of + slick.slideCount);
        });

        oocSlick.slick({
          dots: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 300,
          fade: false,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
           {
              breakpoint: 480,
              settings: {
                /*centerMode: false,*/
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            /*{
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }*/
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ]         
        });

        // Custom carousel nav
        $('.main-slider-prev').click(function(){ 
            $(this).closest('.-home-highlight-wrap').find('#main-slider').slick('slickPrev');
        } );
        
        $('.main-slider-next').click(function(e){
            e.preventDefault(); 
            $(this).closest('.-home-highlight-wrap').find('#main-slider').slick('slickNext');
        } );
    }
    /*Tabs*/ 
    $('.tab-btn').click(function(){
        var target = $(this).data('tab');
        var wrap = $(this).closest('.tabs');
        var tabs = wrap.find('.tab');
        var buttons = wrap.find('.tab-btn');
        buttons.removeClass('active');
        /*tabs.removeClass('visible').removeClass('animate__fadeIn');*/
        tabs.removeClass('visible');

        /*wrap.find('#'+target).addClass('visible').addClass('animate__fadeIn');*/
        wrap.find('#'+target).addClass('visible');
        $(this).addClass('active');
    });   


    /*Test Function to add Active in nav links - REMOVE FROM THEME*/
    window.onload = function() {
      var currentUri = window.location.href;
      var latest = currentUri.split('/').pop();
      
      
        var main_nav = document.querySelectorAll(".-header-navbar--nav");

        for (var mn = 0; mn < main_nav.length; mn++) {
          var children = main_nav[mn].children;
          if (children.length) {
              for (var childI = 0; childI < children.length; childI++) {
                var links = children[childI].getElementsByTagName('a');
                var target = links[0].href;
                  if(target.indexOf('#') !== -1){
                    /*invalid url*/
                  }else{
                    /*console.log(latest);*/
                    if (latest) {
                      if(target.indexOf(latest) !== -1){
                        /*console.log(children[childI]);*/
                        children[childI].classList.add('active');
                      }
                    }
                      
                  }
              }
          }

          

            // ddlTrigger[mn].addEventListener('click', menuItemsReader, false);
        }
    };


    /*field masks*/
    function mask(o, f) {
        setTimeout(function () {
            var v = f(o.value);
            if (v != o.value) {
                o.value = v;
            }
        }, 1);
    }
    
    function mphone(v) {
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
        v = v.replace(/(\d)(\d{4})$/, "$1-$2");
        return v;
    }
    
    function mcep(v) {
        v = v.replace(/\D/g, "");
        v = v.replace(/^(\d{5})(\d{3})?/, "$1-$2");
        return v;
    }
    
    function mnum(v) {
        v = v.replace(/\D/g, "");
        return v;
    }
    
    function memail(v) {
        v = v.replace(/[()+=#%!{}\[\];:,<>?/\|""''&*°\\/´`~^$¨]/g, "");
        var parts = v.split('@');
        v = "";
        for(var i = 0; i < parts.length; i++){
            if(i == 1){
                v += "@";
            }
            v += parts[i];
        }
        
        return v;
    }
    
    function mtext(v) {
        v = v.replace(/[0123456789()_+-=#@%!{}\[\];:,<>?/\|""''&*°\\/´`~^$¨]/g, "");
        return v;
    }

    function price(v) {
        v = v.replace(/\D/g, "");
        v = v.replace(/([0-9]{2})$/g, ",$1");
        v = v.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        v = v.replace(/([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2,$3");
        v = v.replace(/([0-9]{3}).([0-9]{3}).([0-9]{3}),([0-9]{2}$)/g, ".$1.$2.$3,$4");
        return v;
    }

    function cpfCnpj(v){
     
        //Remove tudo o que não é dígito
        v=v.replace(/\D/g,"")
     
        if (v.length < 14) { //CPF   405.604.628-08  43.201.151/0001-99
     
            //Coloca um ponto entre o terceiro e o quarto dígitos
            v=v.replace(/(\d{3})(\d)/,"$1.$2")
     
            //Coloca um ponto entre o terceiro e o quarto dígitos
            //de novo (para o segundo bloco de números)
            v=v.replace(/(\d{3})(\d)/,"$1.$2")
     
            //Coloca um hífen entre o terceiro e o quarto dígitos
            v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
     
        } else { //CNPJ
            console.log('aki');
            //Coloca ponto entre o segundo e o terceiro dígitos
            v=v.replace(/^(\d{2})(\d)/,"$1.$2")
     
            //Coloca ponto entre o quinto e o sexto dígitos
            v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
     
            //Coloca uma barra entre o oitavo e o nono dígitos
            v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
     
            //Coloca um hífen depois do bloco de quatro dígitos
            v=v.replace(/(\d{4})(\d)/,"$1-$2")
     
        }
     
        return v
     
    }    

    
    $('input[type="tel"],.mask-tel').on('keydown', function(e){
        mask(this, mphone);
    });
    
    $('input[type="email"],.mask-email').on('keydown', function(e){
        mask(this, memail);
    });

    $('input.mask-price').on('keydown', function(e){
        mask(this, price);
    });
    
    
    $('input.mask-cep').on('keydown', function(e){
        mask(this, mcep);
    });
    
    $('input.mask-num').on('keydown', function(e){
        mask(this, mnum);
    });
    
    $('input.mask-text').on('keydown', function(e){
        mask(this, mtext);
    });
    
    $('input[type="tel"],.mask-tel').change(function() {
        mask(this, mphone);
    });
    
    $('input[type="email"],.mask-email').change(function() {
        mask(this, memail);
    });
    
    
    $('input.mask-cep').change(function() {
        mask(this, mcep);
    });
    
    $('input.mask-num').change(function() {
        mask(this, mnum);
    });
    
    $('input.mask-text').change(function() {
        mask(this, mtext);
    });
    
    
    
    $('input[type="tel"],.mask-tel').on('keydown', function(e){
        mask(this, mphone);
    });
    
    $('input[type="email"],.mask-email').on('keydown', function(e){
        mask(this, memail);
    });
    
    
    $('input.mask-cep').on('keydown', function(e){
        mask(this, mcep);
    });
    
    $('input.mask-num').on('keydown', function(e){
        mask(this, mnum);
    });
    
    $('input.mask-text').on('keydown', function(e){
        mask(this, mtext);
    });

    $(".input-documento").keypress(function() {
      mask(this, cpfCnpj);
    });

    /*CEP autocomplete*/
    $(".cep-complete").blur(function(){
        var address = $(this).closest('form').find(".endereco");
        var neighborhood = $(this).closest('form').find(".bairro");
        var city = $(this).closest('form').find(".cidade");
        var state = $(this).closest('form').find(".estado");
        var cep = this.value.replace(/[^0-9]/, "");
        if(cep.length != 8){
            return false;
        }        
        var url = "https://viacep.com.br/ws/"+cep+"/json/";
        $.getJSON(url, function(dadosRetorno){ console.log(dadosRetorno);
            try{
                address.val(dadosRetorno.logradouro);
                neighborhood.val(dadosRetorno.bairro);
                city.val(dadosRetorno.localidade);
                state.val(dadosRetorno.uf);
            }catch(ex){}
        });
    });


    


    //Menu trigger
    var menuTrigger = document.getElementsByClassName("menu-trigger-btn"),
        menu = document.getElementById("main-nav");
    
    var toggleMenu = function() {
        console.log('main nav offcanvas called')
        //menu.classList.toggle("offcanvas-nav-active");
        document.body.classList.toggle("menu-active");
    };

    for (var i = 0; i < menuTrigger.length; i++) {
        menuTrigger[i].addEventListener('click', toggleMenu, false);
    }


    //Offcanvas Form trigger
    var modalTrigger = document.getElementsByClassName("modal-trigger-btn"),
        offcanvasForm = document.getElementById("main-menu");
    
    var toggleCanvasModal = function() {
        console.log('modal called');
        event.preventDefault();
        /*document.body.classList.toggle("modal-active");*/


        if (document.body.classList.contains('modal-active')) {
            document.body.classList.remove("modal-active");
        }else{
            document.body.classList.add("modal-active");
        }
        /*offcanvasForm.classList.toggle("main-menu-active");*/
        /*document.body.classList.toggle("active-form-contact");*/
    };

    for (var i = 0; i < modalTrigger.length; i++) {
        modalTrigger[i].addEventListener('click', toggleCanvasModal, false);
    }


    /*Hide all forms*/
    function addClasstoElemByClass(addClass, elementByClassName){
        var elClass = document.getElementsByClassName(elementByClassName);
        for (var f = 0; f < elClass.length; f++) {
            elClass[f].classList.add(addClass);
        }
    }

   
    /*Anchor scroll*/
    const anchors = document.querySelectorAll(".scroll-anchor");
     
    for (const anchor of anchors) {
      anchor.addEventListener("click", clickHandler);
    }
     
    function clickHandler(e) {
      e.preventDefault();
      const header = document.querySelector("header").offsetHeight;
      const href = this.getAttribute("href");
      const offsetTop = document.querySelector(href).offsetTop;
     
      scroll({
        top: offsetTop-header,
        behavior: "smooth"
      });
    }

}(window.jQuery, window, document));